import Vue from 'vue'
import Toasted from 'vue-toasted';
import { LMap, LTileLayer, LMarker, LPopup } from 'vue2-leaflet';
import { Icon } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import ECharts from 'vue-echarts';
import vuetify from './plugins/vuetify';
import './registerServiceWorker'

import App from './App.vue'
import firebaseConfig from './firebaseConfig';
import Logo from '@/components/Logo';
import Footer from '@/components/Footer';
import Sidebar from './views/Sidebar';
import Home from './views/Home';
import SensorView from './views/SensorView';
import TemperatureView from './views/TemperatureView';
import Login from './views/Login';

import store from './store/store';

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  // iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  // iconUrl: require('leaflet/dist/images/marker-icon.png'),
  // shadowUrl: require('@/assets/icons/android-chrome-192x192.png'),
  shadowUrl: require('@/assets/icon/favicon.png'),
});

Vue.component('e-logo', Logo);
Vue.component('e-footer', Footer);
Vue.component('l-map', LMap);
Vue.component('l-popup', LPopup);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.component('v-chart', ECharts);
Vue.component('sidebar', Sidebar);
Vue.component('home', Home);
Vue.component('sensor-view', SensorView);
Vue.component('temperature-view', TemperatureView);
Vue.component('login', Login);


Vue.config.productionTip = false
Vue.use(firebaseConfig);
Vue.use(Toasted)
Vue.mixin({
	data () {
		return {
			store,
		}
	},
})

new Vue({
	vuetify,
	render: h => h(App)
}).$mount('#app');

