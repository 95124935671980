<template>
	<div data-app v-cloak>
		<div class="map-container">
			<l-map
				:zoom="zoom"
				:center="center"
				:options="mapOptions"
				style="height: 100%"
			>
				<l-tile-layer :url="url" :attribution="attribution" />
				<l-marker
					v-for="erayInfo in eraysInfo"
					:key="erayInfo.name"
					:lat-lng="[
						erayInfo.meta.location._latitude,
						erayInfo.meta.location._longitude,
					]"
				>
					<l-popup>
						<div>
						{{ erayInfo.name }}
						</div>
					</l-popup>
				</l-marker>
			</l-map>
		</div>
	</div>
</template>

<script>

import { latLng } from "leaflet";

export default {
	name: "Home",
	data () {
		return {
			loading: true,
			eraysInfo: {},
			zoom: 13,
			center: latLng(0, 0),
			url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
			attribution:
			'&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
			mapOptions: {
				zoomSnap: 0.5,
			},
		};
	},
	mounted () {
		this.eraysInfo = this.store.eraysInfo;
		this.center = latLng(
			this.store.selectedEray.meta.location._latitude,
			this.store.selectedEray.meta.location._longitude
		);
	},
};
</script>

<style lang="scss" scoped>
@import "@/scss/_colors.scss";
@import "@/scss/_mixins.scss";
.map-container {
  border-top: 1px solid $eray-blue;
  padding: 1%;
  height: 800px;
  width: 100%;
}
</style>