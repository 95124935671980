// import firebase from 'firebase';
import firebase from 'firebase/app';
import 'firebase/auth';
// import 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyBsWWyrcc9jpcikkT8VurMj7TBvd9wxZyw',
  authDomain: 'e-ray-e7f7e.firebaseapp.com',
  databaseURL: 'https://e-ray-e7f7e.firebaseio.com',
  projectId: 'e-ray-e7f7e',
  storageBucket: 'e-ray-e7f7e.appspot.com',
  messagingSenderId: '933476966520' 
};

export default firebase.initializeApp(firebaseConfig);