export function desktop () {
    return window.matchMedia('(min-width: 1025px)').matches;
}

export function tablet () {
    return window.matchMedia('(min-width: 768px) and (max-width: 1024px)').matches;
}

export function mobileS () {
    return window.matchMedia('(max-width: 320px)').matches;
}

export function mobileM () {
    return window.matchMedia('(min-width: 321px) and (max-width: 375px)').matches;
}

export function mobileL () {
    return window.matchMedia('(min-width: 376px) and (max-width: 425px)').matches;
}

export function mobileXL () {
    return window.matchMedia('(min-width: 426px) and (max-width: 767px)').matches;
}

export function mobile () {
	return Boolean(mobileS() || mobileM() || mobileL() || mobileXL());
}