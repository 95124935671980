<template>
    <div>
        <div>
            <v-row>
                <v-col cols="12">
                <v-img
                    alt="e.Ray"
                    src="@/assets/home/logo.svg"
                    contain
                    max-height="200"
                    min-height="50"
                />
                </v-col>
            </v-row>
        </div>
        <div class="input-container">
            <input @keyup.enter='login()' v-model='email' type="text" placeholder="E-Mail" class="form-control" >
        </div>
        <div class="input-container"> 
            <input @keyup.enter='login()' v-model='password' type="password" placeholder="Password">
        </div>
        <v-btn
            @click='login()'
            outlined
            color="#005796"
            :loading="loading"
        >
            Login
        </v-btn>
    </div>
</template>

<script>
import firebase from '../firebaseConfig'; 
import axios from 'axios';

export default {
    mounted: async function () {
        const identity = this.store.uid;
        if(identity) {
            this.setUpProcess(identity);
        } else {
            this.loading = false;
        }
    },
    data () {
        return {
            email: '',
            password: '',
            loading: true,
        }
    },
    methods: {
		/**
		 * This method tries to log the user in
		 */
        async login() {
            this.loading = true;
            try {
                const res = await firebase.auth().signInWithEmailAndPassword(this.email, this.password);
                if (res.user) {
                    this.setUpProcess(res.user.uid);
                } else {
                    this.showToast('Internal Server Problem');
                    this.loading = false;
                }   
            } catch (err) {
				this.showToast('Invalid Credentials');
                this.loading = false;
            }
        },
		/**
		 * Show a floating message
		 */
        showToast (message) {
            this.$toasted.show(message, { 
                theme: 'outline', 
                position: 'bottom-center',
                duration : 2000
            });
        },
		/**
		 * Once a log in is successful this method start fetching all needed information
		 */
        async setUpProcess (uid) {
            this.showToast('Logging in...');
			const loginData = await axios.get(`${this.store.api.userInfo}/${uid}`);
			
            if(loginData) {
				this.store.set('userInfo', loginData.data);
				this.store.set('uid', uid);
				let axiosAll = [];
				let eraysInfo = {};
				this.store.userInfo.eraylist.map((eray) => {
					axiosAll.push(axios.get(`${this.store.api.eraysInfo}/${eray}`));
				});

				const fetchEraysInfoSuccessful = await axios.all(axiosAll).then(responseArr => {
					responseArr.map(responseObj => {
						const key = Object.keys(responseObj.data);
						eraysInfo[key] = (responseObj.data[key]);
					})
					return true;
				});

				this.store.set('eraysInfo', eraysInfo);
				

                if(fetchEraysInfoSuccessful) {
					this.store.userLogged = true;
                }
            }  
        }
    }
}
</script>

<style scoped>
input {
    width: 95%;
    margin: 10px;
    border: none;
}
button {
    width: 95%;
}
</style>