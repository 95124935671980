module.exports = {
    server: {
        url: 'https://us-central1-e-ray-e7f7e.cloudfunctions.net/post',
        // url: 'http://localhost:5001/e-ray-e7f7e/us-central1/post/',
        port: 80,
        endpoints: {
            erays: 'erays',
            user: 'users',
        },
    },
};