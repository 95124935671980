<template>
    <div id="app">
        <v-card color="grey lighten-4" flat tile shaped>
            <v-toolbar v-if="store.userLogged">
                <v-btn icon>
                    <!-- <v-icon>mdi-bell</v-icon> -->
                </v-btn>

                <v-spacer />
				
                <div @click="showMap">
                    <e-logo />
                </div>

                <v-spacer />

                <v-btn icon @click="logout">
                    <v-icon color="#005796">mdi-export</v-icon>
                </v-btn>
            </v-toolbar>
        </v-card>
        <login v-if="!store.userLogged"></login>
        <div v-else>
            <v-row>
                <v-col cols="3">
                    <sidebar></sidebar>
                </v-col>
                <v-col cols="9">
                    <home v-if="store.selectedSensor === 'map'"></home>
                    <temperature-view v-else-if="store.selectedSensor === 'temperature'"></temperature-view>
                    <sensor-view v-else></sensor-view>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import goHomeMixin from "@/mixins/goHome";
export default {
  mixins: [goHomeMixin],
  computed: {
    fullHeight: function () {
        return { height: window.innerHeight + "px" };
    },
  },
  methods: {
    async showMap() {
        this.store.set('selectedSensor', 'map');
    },
    async logout() {
        this.store.clear();
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/_colors.scss";
@import "@/scss/_mixins.scss";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  height: 100%;
  position: relative;
}

.footer-container {
  width: 100%;
}

.router-container {
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow-y: scroll;
}
.router-container::-webkit-scrollbar {
  display: none;
}
</style>
