import config from '../config';

export class Store {
	static instance = new Store();
	api = {
		userInfo: `${config.server.url}/${config.server.endpoints.user}`,
		eraysInfo: `${config.server.url}/${config.server.endpoints.erays}`,
		sensorInfo: `${config.server.url}/${config.server.endpoints.erays}`,
		updateLevel: `${config.server.url}/${config.server.endpoints.erays}/update`,
		updateDefault: `${config.server.url}/${config.server.endpoints.erays}/default`,
	}
	uid = '';
	userInfo = {};
	eraysInfo = {};
	selectedSensor = 'map';
	selectedEray = {};
	userLogged = false;

	/**
	 * The constructor fetches the previous values from localStorage 
	 */
	constructor () {
		const uid = window.localStorage.getItem('e-ray:uid') || false;
		if (uid) {
			this.uid = JSON.parse(decodeURIComponent(uid));
			this.userInfo = JSON.parse(decodeURIComponent(window.localStorage.getItem('e-ray:userInfo')));
			this.eraysInfo = JSON.parse(decodeURIComponent(window.localStorage.getItem('e-ray:eraysInfo')));
			this.selectedSensor = JSON.parse(decodeURIComponent(window.localStorage.getItem('e-ray:selectedSensor')));
			this.selectedEray = JSON.parse(decodeURIComponent(window.localStorage.getItem('e-ray:selectedEray')));
			this.userLogged = true;
		}
	}
	static init () {
		return this.instance;
	}

	/**
	 * Saves a key-value pair in the localStorage
	 */
	set (key, value) {
		window.localStorage.setItem(`e-ray:${key}`, encodeURIComponent(JSON.stringify(value)));
		this[key] = value;
	}

	clear () {
		this.uid = '';
		this.userInfo = {};
		this.eraysInfo = {};
		this.selectedSensor = 'map';
		this.selectedEray = {};
		window.localStorage.removeItem('e-ray:uid');
		window.localStorage.removeItem('e-ray:userInfo');
		window.localStorage.removeItem('e-ray:eraysInfo');
		window.localStorage.removeItem('e-ray:selectedSensor');
		window.localStorage.removeItem('e-ray:selectedEray');
		this.userLogged = false;
	}

	getErayList (applyRegex) {
		if (applyRegex) {
			return this.userInfo.eraylist.map(eray => eray.replace(/_/g, ' '));
		}
		return this.userInfo.eraylist;
	}

	getDefaultErayName (applyRegex) {
		if (applyRegex) {
			return this.userInfo.info.default.replace(/_/g, ' ');
		}
		return this.userInfo.info.default;
	}

	updateEraySelection (selectedEray) {
		this.selectedEray = selectedEray;
		this.userInfo.info.default = selectedEray.codename;
	}
}

export default Store.init();