// eslint-disable-next-line no-unused-vars
/**
 * This mixin creates a .csv file for the current graph being shown
 */
import moment from 'moment';    

export function downloadCSV(selected_eray, sensor, unit, currently_showing,
selected_year, selected_month, selected_day,
current_year, current_month, current_day,
readinglist) {

    let csv = '';

    csv += 'ERAY;SENSOR;UNIT\n';
    csv += selected_eray+';'+sensor+';'+unit+'\n\n';

    if (currently_showing === 'LATEST' || currently_showing==='CUSTOM_SINGLE') {

        let singleDay;

        if(currently_showing === 'LATEST'){
            singleDay = current_year+'-'+current_month+'-'+current_day;
        }
        else{
            singleDay = selected_year+'-'+selected_month+'-'+selected_day;
        }

        csv += 'DATE\n'+singleDay+'\n\nTIME;VALUE\n';

        for(let i = 0; i <readinglist.length;i++){
            csv += readinglist[i][0] + ';' + readinglist[i][1] + '\n';
        }
    }

    else {
        let maxLength = 0;

        readinglist.forEach(daylist => {
            console.log(daylist)
            if (daylist.length >= maxLength) maxLength = daylist.length;
        });

        for (let i = 0;i < readinglist.length; i++) {
            csv += 'DATE,,,';
        }

        csv += '\n';

        for (let i = 0;i < readinglist.length; i++) {
            csv += moment(readinglist[i][0][0]).year() + '-' + (+moment(readinglist[i][0][0]).month() + 1) + '-' + moment(readinglist[i][0][0]).date() + ',,,';
        }

        csv += '\n';

        for (let i = 0;i < readinglist.length; i++) {
            csv += 'TIME,VALUE,,';
        }

        csv += '\n';

        for (let i = 0; i < maxLength; i++) {
            readinglist.forEach(daylist => {
                if (daylist[i]) {
                    csv += moment(daylist[i][0]).hours() + ':' + moment(daylist[i][0]).minutes() + ',' + daylist[i][1] + ',,';
                }
            });
            csv += '\n';
        }
        
    }

    const FileSaver = require('file-saver'); 
    const blob = new Blob([csv], {type: "text/plain;charset=utf-8"});
    FileSaver.saveAs(blob, `report-${selected_eray}-${sensor}-${current_year}-${current_month}-${current_day}.csv`);  
} 
