<template>
	<div data-app v-cloak>
		<div v-if="!emptyResponse">
			<v-row style="margin: 1%" justify="center" cols="12">
				<v-col cols="4">
					<div class="time-container">
						<v-btn
							class="time-button"
							:class="{ 'time-button--active': latest }"
							@click="changeTime('LATEST')"
							text>
							LATEST
						</v-btn>
					</div>
				</v-col>
				<v-col cols="4">
					<div class="time-container">
						<v-btn
							class="time-button"
							:class="{ 'time-button--active': week }"
							@click="changeTime('WEEK')"
							text>
							WEEK
						</v-btn>
					</div>
				</v-col>
				<v-col cols="4">
					<div class="time-container">
						<v-btn
							class="time-button"
							:class="{ 'time-button--active': month }"
							@click="changeTime('MONTH')"
							text>
							MONTH
						</v-btn>
					</div>
				</v-col>
			</v-row>
			<v-skeleton-loader
				:loading="loading"
				style="margin: 1.5%"
				type="article, article">
                <v-row>
                    <v-col>
                        <v-card>
                            <v-card-text>
                                <div v-if="waterlevel">
                                    Water level:
                                    {{((data.calculations.latestData - selectedEray.levels.riverbed) * 100).toFixed(0)}} cm
                                </div>
                                <div class="text--primary">
                                    Last Reading: {{ parseFloat(data.calculations.latestData).toFixed(2) }} {{ unit }}
                                </div>
                                <div v-if="waterlevel">(Meters Above Mean Sea Level)</div>
                                <p class="display-1 text--primary">
                                    At: {{ data.timeConstrains.formattedTime }}
                                </p>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-card>
                    <div class="chart-wrapper">
                        <v-chart class="eraycharts" :options="chartOptions" />
                    </div>
                </v-card>
                <div v-if="waterlevel">
                    <v-row>
                        <v-col class="xs-12 sm-12 md-4">
                            <v-card>
                                <v-card-text>
                                    <div>Set River-/Lakebed:</div>
                                    <p class="display-1 text--primary">
                                        <input
                                        v-model="selectedEray.levels.riverbed"
                                        type="text"
                                        placeholder="Riverbed"
                                        />
                                        <v-btn
                                            @click="updateLevel('riverbed')"
                                            class="ma-2"
                                            color="#005796"
                                            dark>
                                            Set
                                            <v-icon dark right>mdi-checkbox-marked-circle</v-icon>
                                        </v-btn>
                                    </p>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col class="xs-12 sm-12 md-4">
                            <v-card>
                                <v-card-text>
                                    <div>Set Warning Level:</div>
                                    <p class="display-1 text--primary">
                                        <input
                                        v-model="selectedEray.levels.warning"
                                        type="text"
                                        placeholder="Warning Level"
                                        />
                                        <v-btn
                                            @click="updateLevel('warning')"
                                            class="ma-2"
                                            color="#005796"
                                            dark>
                                            Set
                                            <v-icon dark right>mdi-checkbox-marked-circle</v-icon>
                                        </v-btn>
                                    </p>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col class="xs-12 sm-12 md-4">
                            <v-card>
                                <v-card-text>
                                    <div>Set Alert Level:</div>
                                    <p class="display-1 text--primary">
                                        <input
                                            v-model="selectedEray.levels.alert"
                                            type="text"
                                            placeholder="Alert Level"
                                        />
                                        <v-btn
                                            @click="updateLevel('alert')"
                                            class="ma-2"
                                            color="#005796"
                                            dark>
                                            Set
                                            <v-icon dark right>mdi-checkbox-marked-circle</v-icon>
                                        </v-btn>
                                    </p>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-expansion-panels>
                        <v-expansion-panel>
                            <v-expansion-panel-header>Alarm Settings</v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-row>
                                    <v-col class="xs-6 sm-6 md-4">
                                        <v-checkbox
                                        v-model="selectedAlarms.Email"
                                        label="Email"
                                        ></v-checkbox>
                                    </v-col>
                                    <v-col class="xs-6 sm-6 md-4">
                                        <v-checkbox
                                        v-model="selectedAlarms.SMS"
                                        label="SMS"
                                        ></v-checkbox>
                                    </v-col>
                                    <v-col class="xs-6 sm-6 md-4">
                                        <v-checkbox
                                        v-model="selectedAlarms.LoRa"
                                        label="LoRa"
                                        ></v-checkbox>
                                    </v-col>
                                    <v-col class="xs-6 sm-6 md-4">
                                        <v-checkbox
                                        v-model="selectedAlarms.Whatsapp"
                                        label="Whatsapp"
                                        ></v-checkbox>
                                    </v-col>
                                    <v-col class="xs-6 sm-6 md-4">
                                        <v-checkbox
                                        v-model="selectedAlarms"
                                        label="Twitter"
                                        ></v-checkbox>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </div>
                <v-btn
                    outlined
                    color="#005796"
                    :loading="loading"
                    style="margin: 1.5%"
                    @click="downloadCSV"
                    v-if="latest">
                    Download CSV
                </v-btn>
            </v-skeleton-loader>
        </div>
        <div v-else>
            <span>No data to show</span>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import ECharts from 'vue-echarts';
import 'echarts/lib/chart/line';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/markLine';
import 'echarts/lib/component/legend';
import 'echarts/lib/component/title';
import axios from 'axios';

import { desktop } from '@/mixins/breakPoints';
import { downloadCSV } from '@/mixins/downloadCSV';

export default {
    components: {
        'v-chart': ECharts,
    },
    computed: {
        waterlevel: function () {
            return this.selectedSensor === 'waterlevel';
        },
        ph: function () {
            return this.selectedSensor === 'ph';
        },
        precipitation: function () {
            return this.selectedSensor === 'precipitation';
        },
        latest: function () {
            return this.selectedTime === 'LATEST';
        },
        week: function () {
            return this.selectedTime === 'WEEK';
        },
        month: function () {
            return this.selectedTime === 'MONTH';
        },
		/**
		 * Specifies the unit depending on the selected sensor
		 */
        unit: function () {
            const units = {
                waterlevel: 'm AMSL',
                ph: '',
                precipitation: 'Milimmeters',
				voltage: 'V',
				current: 'A',
            };
            return units[this.selectedSensor];
        },
        riverbed: function () {
            return this.selectedEray.levels.riverbed;
        },
        warning: function () {
            return this.selectedEray.levels.warning;
        },
        alert: function () {
            return this.selectedEray.levels.alert;
        },
    },
	/**
	 * Resets the view every time a sensor is selected
	 */
    mounted: function () {
		window.addEventListener('selectedSensor', () => {
			if (this.store.selectedSensor !== 'temperature' && this.store.selectedSensor !== 'map') {
				this.clearView();
				this.init();
			}
		});
        this.init();
    },
    methods: {
        init: function () {
            this.selectedSensor = this.store.selectedSensor;
            this.selectedEray = this.store.selectedEray;
            this.selectedAlarms = this.selectedEray.alarms;
            this.downloadLatestReadings();
        },
		clearView: function () {
			this.loading = true;
			this.emptyResponse = false;
			this.selectedTime = 'LATEST';
			this.dat = {
				calculations: {
					avgData: '',
					minData: '',
					maxData: '',
					latestData: '',
				},
				timeConstrains: {
					lastReadingTime: '',
					lastWeek: '',
					lastMonth: '',
					formattedTime: '',
				},
				latestReadings: [],
			};
			this.chartOptions = {
				tooltip: {
					trigger: 'axis',
				},
				grid: {
					containLabel: true,
				},
				color: ['#005796'],
				dataZoom: [{type: 'inside',},],
				legend: {
					data: ['Min-Avg-Max', 'Riverbed', 'Warning', 'Alert'],
					bottom: 10,
				},
				xAxis: [],
				yAxis: [
					{
						type: 'value',
						interval: 3,
						axisLabel: {
							formatter: function (value) {
								return value.toFixed(2);
							},
						},
					},
				],
				series: [],
			};
		},
        addSeries: function (data) {
            const serie = {
                symbolSize: 1,
                name: 'data',
                data: data,
                type: 'line',
            };
            this.chartOptions.series.push(serie);
        },
		/**
		 * Calculates average for the selected sensor
		 * TODO: Optimize for "reduce"
		 */
        calculateAvg: function () {
            let sum = 0;
            this.data.latestReadings.forEach((reading) => sum += +reading[1]);
            this.data.calculations.avgData = (sum / this.data.latestReadings.length).toFixed(2);
        },
		/**
		 * Downloads information dependin on the period of time
		 */
        changeTime: function (time) {
            if (time !== this.selectedTime) {
                this.selectedTime = time;
                if (this.latest) {
					this.downloadLatestReadings();
                } else {
					this.downloadMultipleDaysReadings(time);
                }
            }
        },
		/**
		 * Passes pertinen nformation to the downloadCSV mixin
		 */
        downloadCSV: function () {
            if (this.latest || this.selectedTime === 'CUSTOM_SINGLE')
                downloadCSV(
                    this.selectedEray.name,
                    this.selectedSensor,
                    this.unit,
                    this.selectedTime, 
					'',
                    '',
                    '', 
					moment(this.data.timeConstrains).year(),
                    moment(this.data.timeConstrains).month() + 1,
                    moment(this.data.timeConstrains).date(),
                    this.data.latestReadings.map((r) => [`${moment(r[0]).get('hour')}:${moment(r[0]).minutes()}`,r[1]]),
                    this.data.latestReadings.map((r) => `${moment(r[0]).get('hour')}:${moment(r[0]).minutes()}`),
                    this.data.latestReadings.map((r) => r[1]));
            else if (this.week)
                downloadCSV(
                    this.selectedEray.name,
                    this.selectedSensor,
                    this.unit,
                    this.selectedTime,
                    moment(this.data.lastWeek).year(),
                    moment(this.data.lastWeek).year(),
                    moment(this.data.lastWeek).year(),
                    moment(this.data.timeConstrains).year(),
                    moment(this.data.timeConstrains).month() + 1,
                    moment(this.data.timeConstrains).date(),
                    this.data.latestReadings.map((r) => r[1]),
                    this.data.latestReadings.map((r) => `${moment(r[0]).month() + 1}-${moment(r[0]).date()}`),
                    this.data.latestReadings.map((r) => r[1])
                );
            else if (this.month)
                downloadCSV(
                    this.selectedEray.name,
                    this.selectedSensor,
                    this.unit,
                    this.selectedTime,
                    moment(this.data.lastMonth).year(),
                    moment(this.data.lastMonth).year(),
                    moment(this.data.lastMonth).year(),
                    moment(this.data.timeConstrains).year(),
                    moment(this.data.timeConstrains).month() + 1,
                    moment(this.data.timeConstrains).date(),
                    this.data.latestReadings.map((r) => r[1]),
                    this.data.latestReadings.map((r) => `${moment(r[0]).get('hour')}:${moment(r[0]).minutes()}`),
                    this.data.latestReadings.map((r) => r[1])
                );
        },
		/**
		 * Download same day readings from sensor
		 */
        downloadLatestReadings: async function () {
            this.loading = true;
            this.chartOptions.series.length = 0;
			// TODO Optimize these axios calls, remove then, makes no sense to use then with async/await
            const response = await axios.get(`${this.store.api.sensorInfo}/${this.store.selectedEray.codename}/${this.store.selectedSensor}`).then(response => response.data)
            .catch(() => undefined);
            if (response) {
                this.data.latestReadings = Object.entries(response.readings)[0][1];
                this.data.calculations.latestData = this.data.latestReadings.slice(-1)[0][1];
                this.data.timeConstrains.lastReadingTime = this.data.latestReadings.slice(-1)[0][0];
                this.data.timeConstrains.formattedTime = moment(this.data.timeConstrains.lastReadingTime);

                this.addSeries(this.data.latestReadings);
                this.findMin();
                this.findMax();
                this.calculateAvg();
                this.setXAxisLabels();

                if (this.waterlevel) {
                this.setSecondYAxis();
                this.updateYAxisRange();
                this.setExtraChartOptionsWaterlevel();
                }
                if (this.latest && !this.temperature) this.setExtraChartOptionsLatest();
            } else {
                this.emptyResponse = true;
            }
            this.loading = false;
        },
		/**
		 * Downloads multiple day-readings
		 */
        downloadMultipleDaysReadings: async function (constrain) {
            this.loading = true;
            this.chartOptions.series.length = 0;
            let timeObject = {};
            if (constrain === 'WEEK') {
                const myMoment = moment(
                this.data.timeConstrains.lastReadingTime
                ).format('YYYY-MM-DD');
                this.data.timeConstrains.lastWeek = moment.utc(myMoment).subtract(1, 'weeks').format('YYYY-MM-DD');
                timeObject = {
                from: this.data.timeConstrains.lastWeek,
                to: moment.utc(this.data.timeConstrains.lastReadingTime).format(),
                };
            } else if (constrain === 'MONTH') {
                const myMoment = moment(
                this.data.timeConstrains.lastReadingTime
                ).format('YYYY-MM-DD');
                this.data.timeConstrains.lastMonth = moment
                .utc(myMoment)
                .subtract(1, 'months')
                .format('YYYY-MM-DD');
                timeObject = {
                from: this.data.timeConstrains.lastMonth,
                to: moment.utc(this.data.timeConstrains.lastReadingTime).format(),
                };
            } else if (constrain === 'CUSTOM') {
                console.log(moment(this.lastReadingTime));
            }
            const response = await axios.get(`${this.store.api.sensorInfo}/${this.store.selectedEray.codename}/${this.store.selectedSensor}/${timeObject.from}/${timeObject.to}`).then(response => response.data)
            .catch(() => undefined);
            response.readings.map((extraData) => {
                this.addSeries(extraData[Object.entries(extraData)[0][0]]);
            });

            if (this.waterlevel) {
                this.setExtraChartOptionsWaterlevel();
            }
            this.setXAxisLabels();
            this.loading = false;
        },
		/**
		 * Finds min reading
		 */
        findMin: function () {
            let candidate = this.data.calculations.latestData;
            this.data.latestReadings.map((reading) => {
                reading[1] < candidate ? (candidate = reading[1]) : '';
            });
            this.data.calculations.minData = candidate;
        },
		/*
		 * Finds max reading
		 */
        findMax: function () {
            let candidate = this.data.calculations.latestData;
            this.data.latestReadings.map((reading) => {
                reading[1] > candidate ? (candidate = reading[1]) : '';
            });
            this.data.calculations.maxData = candidate;
        },
		/**
		 * Sets extra options to the cahrt
		 */
        setExtraChartOptionsLatest: function () {
            const calculations = {
                data: [],
                type: 'line',
                name: 'Min-Avg-Max',
                markLine: {
                lineStyle: {
                    color: '#005796',
                },
                data: [
                    {
                    label: {
                        formatter: 'Min: ' + this.data.calculations.minData,
                        position: 'insideEndBottom',
                    },
                    yAxis: this.data.calculations.minData,
                    },
                    {
                    label: {
                        formatter: 'Avg: ' + this.data.calculations.avgData,
                        position: 'insideEndBottom',
                    },
                    yAxis: this.data.calculations.avgData,
                    },
                    {
                    label: {
                        formatter: 'Max: ' + this.data.calculations.maxData,
                        position: 'insideEndTop',
                    },
                    yAxis: this.data.calculations.maxData,
                    },
                ],
                },
            };
            this.chartOptions.series.push(calculations);
        },
		/**
		 * Sets extra information to the chart when in waterlevel sensor
		 */
        setExtraChartOptionsWaterlevel: function () {
            const riverbedLine = {
                data: [],
                type: 'line',
                name: 'Riverbed',
                color: '#8EC728',
                markLine: {
                lineStyle: {
                    color: '#8EC728',
                },
                data: [
                    {
                    label: {
                        formatter: 'Riverbed: ' + this.selectedEray.levels.riverbed,
                        position: 'insideEndTop',
                    },
                    yAxis: this.selectedEray.levels.riverbed,
                    },
                ],
                },
            };

            const warningLine = {
                data: [],
                type: 'line',
                name: 'Warning',
                color: '#FFCC00',
                markLine: {
                lineStyle: {
                    color: '#FFCC00',
                },
                data: [
                    {
                    label: {
                        formatter: 'Warning: ' + this.selectedEray.levels.warning,
                        position: 'insideEndTop',
                    },
                    yAxis: this.selectedEray.levels.warning,
                    },
                ],
                },
            };

            const alertLine = {
                data: [],
                type: 'line',
                name: 'Alert',
                color: '#AF2018',
                markLine: {
                lineStyle: {
                    color: '#AF2018',
                },
                data: [
                    {
                    label: {
                        formatter: 'Alert: ' + this.alert,
                        position: 'insideEndTop',
                    },
                    yAxis: this.alert,
                    },
                ],
                },
            };
            this.chartOptions.series.push(riverbedLine);
            this.chartOptions.series.push(warningLine);
            this.chartOptions.series.push(alertLine);
        },
        setXAxisLabels: function () {
            this.chartOptions.xAxis.length = 0;
            const format = this.latest ? 'HH:mm' : 'YYYY-MM-DD';
            let angle = this.latest ? 0 : !desktop() ? 45 : angle;

            this.chartOptions.xAxis.push({
                type: 'time',
                boundaryGap: true,
                axisLabel: {
                    show: true,
                    rotate: angle,
                    rotateAlways: true,
                    formatter: function (value) {
                        return moment.utc(value).format(format);
                    },
                },
            });
        },
        setSecondYAxis: function () {
            if (this.chartOptions.yAxis.length > 1) this.chartOptions.yAxis.pop();
            const riverbed = this.selectedEray.levels.riverbed;
            const secondYAxis = {
                type: 'value',
                interval: 3,
                axisLabel: {
                    formatter: function (value) {
                        return ((value - riverbed) * 100).toFixed(0) + ' cm';
                    },
                },
            };
            this.chartOptions.yAxis.push(secondYAxis);
        },
		/**
		 * When a water level critical level is change it is updated here and in DB
		 */
        updateLevel: async function (level) {
            const newLevel = {
                level: level,
                value: this.selectedEray.levels[level],
            };
            const response = await axios.put(`${this.store.api.updateLevel}/${this.store.selectedEray.codename}`, newLevel).then(response => response.data);
            if (response.message === 'Updated') {
                this.selectedEray.levels[level] = newLevel.value;
                this.chartOptions.series.map((serie, index) => {
                    if (serie.name.toLowerCase() === level) {
                        this.chartOptions.series[
                        index
                        ].markLine.data[0].yAxis = this.selectedEray.levels[level];
                        this.setSecondYAxis();
                        this.updateYAxisRange();
                    }
                });
            }
        },
        updateYAxisRange: function () {
            const yMin = Math.min(
                this.selectedEray.levels.riverbed,
                this.data.calculations.minData
            );
            const yMax = Math.max(
                this.selectedEray.levels.alert,
                this.data.calculations.maxData
            );

            this.chartOptions.yAxis[0].min = yMin;
            this.chartOptions.yAxis[0].max = yMax;

            this.chartOptions.yAxis[1].min = yMin;
            this.chartOptions.yAxis[1].max = yMax;
        },
    },
    data() {
        return {
			emptyResponse: false,
			selectedAlarms: [],
			selectedSensor: '',
			selectedEray: {
				levels: {
				riverbed: 0,
				alert: 0,
				warning: 0,
				},
			},
			loading: true,
			selectedTime: 'LATEST',
			data: {
				calculations: {
					avgData: '',
					minData: '',
					maxData: '',
					latestData: '',
				},
				timeConstrains: {
					lastReadingTime: '',
					lastWeek: '',
					lastMonth: '',
					formattedTime: '',
				},
				latestReadings: [],
			},
			chartOptions: {
				tooltip: {
					trigger: 'axis',
				},
				grid: {
					containLabel: true,
				},
				color: ['#005796'],
				dataZoom: [{type: 'inside',},],
				legend: {
					data: ['Min-Avg-Max', 'Riverbed', 'Warning', 'Alert'],
					bottom: 10,
				},
				xAxis: [],
				yAxis: [
					{
						type: 'value',
						interval: 3,
						axisLabel: {
							formatter: function (value) {
								return value.toFixed(2);
							},
						},
					},
				],
				series: [],
			},
		};
    },
    watch: {
        riverbed: function (newValue) {
            this.selectedEray.levels.riverbed = newValue.replace(/[^0-9.]/g, '');
        },
        alarm: function (newValue) {
            this.selectedEray.levels.alarm = newValue.replace(/[^0-9.]/g, '');
        },
        warning: function (newValue) {
            this.selectedEray.levels.warning = newValue.replace(/[^0-9.]/g, '');
        },
    },
};
</script>

<style lang='scss' scoped>
@import "@/scss/_colors.scss";
.chart-wrapper {
  width: 100%;
  height: 25%;
}

.echarts {
  width: 100%;
}

.time {
  &-container {
    :hover {
      background: $eray-light-blue;
    }
  }
  &-button {
    :hover {
      background: none;
    }
    width: 100%;
    &--active {
      border-bottom: 1px solid $eray-light-blue;
    }
  }
}
</style>