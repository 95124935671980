<template>
  <div v-if="!loading" data-app v-cloak>
	<v-card>
		<v-btn v-if="mobile" @click="toggle = !toggle" icon>
			<v-icon v-if="toggle" color="#005796">mdi-chevron-triple-up</v-icon>
			<v-icon v-else color="#005796">mdi-chevron-triple-down</v-icon>
		</v-btn>
		<v-select :items="erayList" v-model="selectedErayName" @change="updateEraySelection"></v-select>
		<v-card>
			<v-row no-gutters @click="setSensor('waterlevel')">
				<v-col xs="1" sm="3" md="2" lg="2" xl="2">
					<v-img
						alt="Water level"
						class="menu-tile"
						src="@/assets/home/water.svg"
					/>
				</v-col>
				<v-col v-show="toggle">
					<div>
						<b>Water level</b>
						<span v-if="waterlevel">
							<br />
							<br />
							<b>{{ parseFloat(waterlevel).toFixed(2) }} m AMSL</b>
						</span>
						<span v-else><br /><br /><i>Latest reading not available</i></span>
					</div>
				</v-col>
			</v-row>
		</v-card>

		<v-card>
			<v-row no-gutters @click="setSensor('temperature')">
				<v-col xs="1" sm="3" md="2" lg="2" xl="2">
					<v-img
						alt="Temperature"
						src="@/assets/home/temperature.svg"
						class="menu-tile"
					/>
				</v-col>
				<v-col v-show="toggle">
					<div>
						<b>Temperatures</b>
						<div>
							<br />
							<b>System: </b>
							<span v-if="systemp">
								<b>{{ formatReading(systemp) }} °C</b>
							</span>
							<span v-else><i>Not available</i></span>
							<br />
							<b>Atmospheric: </b>
							<span v-if="weathertemp">
								<b>{{ formatReading(weathertemp) }} °C</b>
							</span>
							<span v-else><i>Not available</i></span>
							<br />
							<b>Water: </b>
							<span v-if="watertemp">
								<b>{{ formatReading(watertemp) }} °C</b>
							</span>
							<span v-else><i>Not available</i></span>
						</div>
					</div>
				</v-col>
			</v-row>
		</v-card>

		<v-card>
			<v-row no-gutters @click="setSensor('ph')">
				<v-col xs="1" sm="3" md="2" lg="2" xl="2">
					<v-img
						alt="pH"
						src="@/assets/home/ph.svg"
						class="menu-tile"
					/>
				</v-col>
				<v-col v-show="toggle">
					<div>
						<b>pH</b>
						<span v-if="ph">
							<br />
							<br />
							<b>{{ formatReading(ph) }}</b>
						</span>
						<span v-else><br /><br /><i>Latest reading not available</i></span>
					</div>
				</v-col>
			</v-row>
		</v-card>

		<v-card>
			<v-row no-gutters @click="setSensor('precipitation')">
				<v-col xs="1" sm="3" md="2" lg="2" xl="2">
					<v-img
						alt="Precipitation"
						src="@/assets/home/precipitation.svg"
						class="menu-tile"
					/>
				</v-col>
				<v-col v-show="toggle">
					<div>
						<b>Precipitation</b>
						<span v-if="precipitation">
							<br />
							<br />
							<b>{{ formatReading(precipitation) }} mm</b>
						</span>
						<span v-else><br /><br /><i>Latest reading not available</i></span>
					</div>
				</v-col>
			</v-row>
		</v-card>

		<v-card>
			<v-row no-gutters @click="setSensor('voltage')">
				<v-col xs="1" sm="3" md="2" lg="2" xl="2">
					<v-img
						alt="Voltage"
						src="@/assets/home/blue_change.svg"
						class="menu-tile"
					/>
				</v-col>
				<v-col v-show="toggle">
					<div>
						<b>Voltage</b>
						<span v-if="voltage">
							<br />
							<br />
							<b>{{ formatReading(voltage) }} V</b>
						</span>
						<span v-else><br /><br /><i>Latest reading not available</i></span>
					</div>
				</v-col>
			</v-row>
		</v-card>

		<v-card>
			<v-row no-gutters @click="setSensor('current')">
				<v-col xs="1" sm="3" md="2" lg="2" xl="2">
					<v-img
						alt="Current"
						src="@/assets/home/blue_energy.svg"
						class="menu-tile"
					/>
				</v-col>
				<v-col v-show="toggle">
					<div>
						<b>Current</b>
						<span v-if="current">
							<br />
							<br />
							<b>{{ formatReading(current) }} A</b>
						</span>
						<span v-else><br /><br /><i>Latest reading not available</i></span>
					</div>
				</v-col>
			</v-row>
		</v-card>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios';
import { mobile } from "@/mixins/breakPoints";
export default {
	name: 'Sidebar',
	/**
	 * Once mounted the default selections are set
	 */
	mounted () {
		this.mobile ? this.toggle = false : this.toggle = true;
		this.eraysInfo = this.store.eraysInfo;
		this.selectedErayName = this.store.getDefaultErayName(true);
		this.updateEraySelection();
		this.loading = false;
	},
	computed: {
		erayList() {
			return this.store.getErayList(true);
		},
		waterlevel() {
			return this.selectedEray?.meta?.readings?.waterlevel;
		},
		systemp() {
			return this.selectedEray?.meta?.readings?.systemp;
		},
		weathertemp() {
			return this.selectedEray?.meta?.readings?.weathertemp;
		},
		watertemp() {
			return this.selectedEray?.meta?.readings?.watertemp;
		},
		ph() {
			return this.selectedEray?.meta?.readings?.ph;
		},
		precipitation() {
			return this.selectedEray?.meta?.readings?.precipitation;
		},
		voltage() {
			return this.selectedEray?.meta?.readings?.voltage;
		},
		current() {
			return this.selectedEray?.meta?.readings?.current;
		},
	},
	data() {
			return {
				loading: true,
				selectedErayName: '',
				selectedEray: {},
				eraysInfo: {},
				mobile: mobile(),
				toggle: true,
			};
	},
	methods: {
			async setSensor (sensor, erayUpdated = false) {
				if (sensor != this.store.selectedSensor ||  erayUpdated) {
					this.store.set('selectedSensor', sensor);
					window.dispatchEvent(new Event('selectedSensor'));
				}
			},
			/**
			 * Updates the eray selection in the UI and in the DB everytime an eray is selected
			 */
			updateEraySelection () {
				this.selectedEray = this.eraysInfo[
					this.selectedErayName.replace(/ /g, '_')
				];
				this.store.updateEraySelection(this.selectedEray);
				axios.put(`${this.store.api.updateDefault}/${this.store.uid}/${this.store.selectedEray.codename}`);
				this.setSensor(this.store.selectedSensor, true);
			},
			/**
			 * Formats temperature readings to ignore the .00 or to allow only 1 number after the comma.
			 * TODO: Make it the default in the backend
			 */
			formatReading (arg) {
				const reading = arg.toString();
				const myRegexp = new RegExp('([0-9]*)(.|,)([0-9])');
				if (reading.includes('.00'))
					return reading.replace('.00', '');
				else return reading.match(myRegexp)[0];
			}
		},
};
</script>

<style lang="scss" scoped>
@import "@/scss/_colors.scss";
@import "@/scss/_mixins.scss";
.menu-tile {
	height: 100px;
	width: 100px;
	&-left {
		margin-left: 50%;
	}
	&-right {
		margin-right: 50%;
	}
}
</style>