<template>
    <div data-app v-cloak>
        <div>
            <v-row style="margin: 1%" justify="center" cols="12">
                <v-col cols="4">
                    <div class="time-container" >
                        <v-btn class="time-button" :class="{'time-button--active' : latest}" @click="changeTime('LATEST')" text >LATEST</v-btn>
                    </div>
                </v-col>
                <v-col cols="4">
                    <div class="time-container" >
                        <v-btn class="time-button" :class="{'time-button--active' : week}" @click="changeTime('WEEK')" text >WEEK</v-btn>
                    </div>
                </v-col>
                <v-col cols="4">
                    <div class="time-container" >
                        <v-btn class="time-button" :class="{'time-button--active' : month}" @click="changeTime('MONTH')" text >MONTH</v-btn>
                    </div>
                </v-col>              
            </v-row>
            
            <v-skeleton-loader :loading="loading" style="margin: 1.5%" type="article, article">                
				<v-row>
                    <v-col class="xs-12 sm-12 md-4">
                        <v-card>
                            <v-card-text>
                                <div class="text--primary">
                                    Last Reading System: {{data.calculations.latestDataSys}} °C
                                </div>
                                <p class="display-1 text--primary">
                                    At: {{data.timeConstrains.lastReadingTimeSysFormatted}}
                                </p>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col class="xs-12 sm-12 md-4">
                        <v-card>
                            <v-card-text>
                                <div class="text--primary">
                                    Last Reading Weather: {{data.calculations.latestDataWeather}} °C
                                </div>
                                <p class="display-1 text--primary">
                                    At: {{data.timeConstrains.lastReadingTimeWeatherFormatted}}
                                </p>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col class="xs-12 sm-12 md-4">
                        <v-card>
                            <v-card-text>
                                <div class="text--primary">
                                    Last Reading Water: {{data.calculations.latestDataWater}} °C
                                </div>
                                <p class="display-1 text--primary">
                                    At: {{data.timeConstrains.lastReadingTimeWaterFormatted}}
                                </p>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-card>
                    <div class="chart-wrapper">
                        <v-chart class="eraycharts" :options="chartOptions"/>
                    </div>
                </v-card>
             </v-skeleton-loader>
        </div>
    </div>
</template>

<script>
/**
 * This View is the same as SensorView but instead of having only 1 axis it has 3
 */
import { desktop } from '@/mixins/breakPoints';

import moment from 'moment';    

import ECharts from 'vue-echarts'
import 'echarts/lib/chart/line'
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/markLine';
import 'echarts/lib/component/legend';
import 'echarts/lib/component/title';
import axios from 'axios';

export default {
    components: {
        'v-chart': ECharts,
    },
    computed: {
        temperature: function () {
            return this.selectedSensor === 'temperature';
        },
        latest: function () {
            return this.selectedTime === 'LATEST';
        },
        week: function () {
            return this.selectedTime === 'WEEK';
        },
        month: function () {
            return this.selectedTime === 'MONTH';
        },
        unit: function () {
            return  '°C';
        }
    },
    created: function () {
		window.addEventListener('selectedSensor', () => {
			if (this.store.selectedSensor === 'temperature') {
				this.clearView();
				this.init();
			}
		});
        this.init();
    },
    methods: {
		init: function () {
			this.selectedSensor = this.getSelectedSensor;
			this.selectedEray = this.getSelectedEray;
			this.downloadLatestReadings();
		},
		clearView: function () {
			this.loading = true;
            this.selectedTime = 'LATEST';
            this.data = {
                calculations: {
                    latestDataSys: '',
                    latestDataWeather: '',
                    latestDataWater: '',
                },
                timeConstrains: {
                    lastReadingTimeSys: '',
                    lastReadingTimeWeather: '',
                    lastReadingTimeWater: '',
                    lastWeek: '',
                    lastMonth: '',
                    formattedTime: '',
                },
                latestReadingsSys: [],
                latestReadingsWeather: [],
                latestReadingsWater: [],
            };
            this.chartOptions = {
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    data: ['System', 'Weather', 'Water'],
                    bottom: 10,
                },
                grid: {
                    containLabel: true,
                },
                dataZoom: [{
                    type: 'inside'
                }],
                xAxis: [],
                yAxis: [{
                    type: 'value',
                    scale: true,
                }],
                series: []
            };
		},
        addSeries: function (data, color) {
            const selectedColor = {
                systemp: '#AF2018',
                weathertemp: '#8EC728',
                watertemp: '#4E7EB8'
            };
            const name = {
                systemp: 'System',
                weathertemp: 'Weather',
                watertemp: 'Water'
            };
            const serie = {
                symbolSize: 1,
                name: name[color],
                data: data,
                type: 'line',
                lineStyle: {color: selectedColor[color]}
            };
            this.chartOptions.series.push(serie);
        },
        changeTime: function (time){
            if(time!==this.selectedTime){
                this.selectedTime = time;
                if(this.latest) {
                    this.downloadLatestReadings();
                } else {
                    this.downloadMultipleDaysReadings(time);
                }
            }
        },
        downloadLatestReadings: async function () {
            this.loading = true;
            this.chartOptions.series.length = 0;
			const response = await axios.get(`${this.store.api.sensorInfo}/${this.store.selectedEray.codename}/${this.store.selectedSensor}`).then(response => response.data)
			.catch(() => undefined);
            this.data.latestReadingsSys = Object.entries(response['systemp'])[0][1];
            this.data.calculations.latestDataSys = this.data.latestReadingsSys.slice(-1)[0][1];
            this.data.timeConstrains.lastReadingTimeSys = this.data.latestReadingsSys.slice(-1)[0][0];
            this.data.timeConstrains.lastReadingTimeSysFormatted = moment.utc(this.data.latestReadingsSys.slice(-1)[0][0]);


            this.data.latestReadingsWeather = Object.entries(response['weathertemp'])[0][1];
            this.data.calculations.latestDataWeather = this.data.latestReadingsWeather.slice(-1)[0][1];
            this.data.timeConstrains.lastReadingTimeWeather = this.data.latestReadingsWeather.slice(-1)[0][0];
            this.data.timeConstrains.lastReadingTimeWeatherFormatted = moment.utc(this.data.latestReadingsWeather.slice(-1)[0][0]);

            this.data.latestReadingsWater = Object.entries(response['watertemp'])[0][1];
            this.data.calculations.latestDataWater = this.data.latestReadingsWater.slice(-1)[0][1];
            this.data.timeConstrains.lastReadingTimeWater = this.data.latestReadingsWater.slice(-1)[0][0];
            this.data.timeConstrains.lastReadingTimeWaterFormatted = moment.utc(this.data.latestReadingsWater.slice(-1)[0][0]);

            this.addSeries(this.data.latestReadingsSys, 'systemp');
            this.addSeries(this.data.latestReadingsWeather, 'weathertemp');
            this.addSeries(this.data.latestReadingsWater, 'watertemp');
            
            this.setXAxisLabels();
            this.loading = false;
        },
        downloadMultipleDaysReadings: async function (constrain){
            this.loading = true;
            this.chartOptions.series.length = 0;
            let timeObject = {};
            if(constrain==='WEEK'){
                const myMoment =  moment(this.data.timeConstrains.lastReadingTime).format("YYYY-MM-DD");
                this.data.timeConstrains.lastWeek = moment.utc(myMoment).subtract(1, 'weeks').format("YYYY-MM-DD");
                timeObject = {
                    from: this.data.timeConstrains.lastWeek,
                    to: moment.utc(this.data.timeConstrains.lastReadingTime).format()
                }
            } 
            else if (constrain === 'MONTH'){
                const myMoment =  moment(this.data.timeConstrains.lastReadingTime).format("YYYY-MM-DD");
                this.data.timeConstrains.lastMonth = moment.utc(myMoment).subtract(1, 'months').format("YYYY-MM-DD");
                timeObject = {
                    from: this.data.timeConstrains.lastMonth,
                    to: moment.utc(this.data.timeConstrains.lastReadingTime).format()
                }
            } else if (constrain === 'CUSTOM'){
                console.log(moment(this.lastReadingTime));
            }
			const response = await axios.get(`${this.store.api.sensorInfo}/${this.store.selectedEray.codename}/${this.store.selectedSensor}/${timeObject.from}/${timeObject.to}`).then(response => response.data)
			.catch(() => undefined);
            response.readings.systemp.map(extraData => {
                this.addSeries(extraData[Object.entries(extraData)[0][0]], 'systemp');
            });

            response.readings.watertemp.map(extraData => {
                this.addSeries(extraData[Object.entries(extraData)[0][0]], 'watertemp');
            });

            response.readings.weathertemp.map(extraData => {
                this.addSeries(extraData[Object.entries(extraData)[0][0]], 'weathertemp');
            });

            this.setXAxisLabels();
            this.loading = false;
        },
        setXAxisLabels: function () {
            this.chartOptions.xAxis.length = 0;
            const format = this.latest ? "HH:mm" : "YYYY-MM-DD";
            let angle = this.latest ? 0 : !desktop() ? 45 : angle;
            
            this.chartOptions.xAxis.push(
                {
                    type: 'time',
                    boundaryGap: true,
                    axisLabel: {
                        show: true,
                        rotate: angle,
                        rotateAlways: true,
                        formatter: function (value) {
                            return moment.utc(value).format(format);
                        }
                    },
                }
            )
        },
        updateYAxisRange: function () {
            this.chartOptions.yAxis[0].min = this.selectedEray.levels.riverbed;
            this.chartOptions.yAxis[0].max = Math.max(this.selectedEray.levels.alert, this.data.calculations.maxData);
        }
    },
    data () {
        return {
            selectedSensor: '',
            selectedEray: {},
            loading: true,
            selectedTime: 'LATEST',   
            data: {
                calculations: {
                    latestDataSys: '',
                    latestDataWeather: '',
                    latestDataWater: '',
                },
                timeConstrains: {
                    lastReadingTimeSys: '',
                    lastReadingTimeWeather: '',
                    lastReadingTimeWater: '',
                    lastWeek: '',
                    lastMonth: '',
                    formattedTime: '',
                },
                latestReadingsSys: [],
                latestReadingsWeather: [],
                latestReadingsWater: [],
            },
            chartOptions: {
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    data: ['System', 'Weather', 'Water'],
                    bottom: 10,
                },
                grid: {
                    containLabel: true,
                },
                dataZoom: [{
                    type: 'inside'
                }],
                xAxis: [],
                yAxis: [{
                    type: 'value',
                    scale: true,
                }],
                series: []
            },
        }
    }
}

</script>

<style lang="scss" scoped>
@import "@/scss/_colors.scss";
.chart-wrapper {
    width: 100%;
    height: 30%;
}
.echarts {
    width: 100%;
}

.time {
    &-container{
        :hover {
            background: $eray-light-blue;
        }
    }
    &-button {
        :hover {
            background: none;
        }
        width:100%;
        &--active {
            border-bottom: 1px solid $eray-light-blue;
        }
    }
}
</style>